import { Check } from "lucide-react";
import {
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  ListBoxProps as AriaListBoxProps,
  Collection,
  Header,
  ListBoxItemProps,
  Section,
  SectionProps,
  composeRenderProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";
import { SelectItemProps } from "./Select";
import { composeTailwindRenderProps, focusRing } from "./utils";

interface ListBoxProps<T>
  extends Omit<AriaListBoxProps<T>, "layout" | "orientation"> {}

/**
 * React-aria-components ListBox implementation with application styles (based on https://react-spectrum.adobe.com/react-aria-tailwind-starter/index.html?path=/docs/listbox--docs)
 * @param props containing
 * children: children items to display in the list
 */
export function ListBox<T extends object>({
  children,
  ...props
}: ListBoxProps<T>) {
  return (
    <AriaListBox
      {...props}
      className={composeTailwindRenderProps(
        props.className,
        "outline-0 p-1 border border-secondary-main-20 rounded"
      )}
    >
      {children}
    </AriaListBox>
  );
}

export const itemStyles = tv({
  extend: focusRing,
  base: "group relative flex items-center gap-8 cursor-default select-none py-1.5 px-2.5 rounded will-change-transform text-sm forced-color-adjust-none",
  variants: {
    isSelected: {
      false:
        "text-secondary-main-100 hover:bg-sidebar-gray-hover -outline-offset-2",
      true: "bg-primary-main-500 text-white forced-colors:bg-[Highlight] forced-colors:text-[HighlightText] [&:has(+[data-selected])]:rounded-b-none [&+[data-selected]]:rounded-t-none -outline-offset-4 outline-white forced-colors:outline-[HighlightText]",
    },
    isDisabled: {
      true: "text-sidebar-gray-hover opacity-50 forced-colors:text-[GrayText]",
    },
  },
});

/**
 * React-aria-components ListBoxItem implementation with application styles
 * @param props containing
 * children: children items to display in the list
 */
export function ListBoxItem(props: ListBoxItemProps) {
  let textValue =
    props.textValue ||
    (typeof props.children === "string" ? props.children : undefined);
  return (
    <AriaListBoxItem {...props} textValue={textValue} className={itemStyles}>
      {composeRenderProps(props.children, (children) => (
        <>
          {children}
          <div className="absolute left-4 right-4 bottom-0 h-px bg-white/20 forced-colors:bg-[HighlightText] hidden [.group[data-selected]:has(+[data-selected])_&]:block" />
        </>
      ))}
    </AriaListBoxItem>
  );
}

export const dropdownItemStyles = tv({
  base: "group flex items-center gap-4 cursor-default select-none py-2 pr-1 rounded-sm outline outline-0 text-sm forced-color-adjust-none",
  variants: {
    variant: {
      primary: "text-secondary-main-100",
      information: "text-information-main-1",
    },
    isDisabled: {
      true: "text-sidebar-gray-hover forced-colors:text-[GrayText]",
    },
    isFocused: {
      true: "bg-primary-main-500 text-white forced-colors:bg-[Highlight] forced-colors:text-[HighlightText]",
    },
    selectable: {
      true: "pl-3",
      false: "pl-1",
    },
  },
  defaultVariants: { variant: "primary" },
  compoundVariants: [
    {
      isFocused: false,
      isOpen: true,
      className: "bg-secondary-main-20",
    },
  ],
});

/**
 * React-aria-components DropdownItem implementation with application styles
 * @param props containing
 * variant: tailwind variant to style component (possible values: "primary" | "information")
 * children: children items to display in the list
 */
export function DropdownItem({ variant, ...props }: SelectItemProps) {
  let textValue =
    props.textValue ||
    (typeof props.children === "string" ? props.children : undefined);
  return (
    <AriaListBoxItem
      {...props}
      textValue={textValue}
      className={composeRenderProps(props.className, (className, renderProps) =>
        dropdownItemStyles({
          ...renderProps,
          variant,
          className,
          selectable: true,
        })
      )}
    >
      {composeRenderProps(props.children, (children, { isSelected }) => (
        <>
          <span className="flex items-center flex-1 gap-2 font-normal truncate group-selected:font-semibold">
            {children}
          </span>
          <span className="flex items-center w-5">
            {isSelected && <Check className="w-4 h-4" />}
          </span>
        </>
      ))}
    </AriaListBoxItem>
  );
}

export interface DropdownSectionProps<T> extends SectionProps<T> {
  title?: string;
}

/**
 * React-aria-components DropdownSection implementation with application styles
 * @param props containing
 * title: the section title
 * items: items contained in the section
 * children: JSX element displaying items of the section
 */
export function DropdownSection<T extends object>(
  props: DropdownSectionProps<T>
) {
  return (
    <Section className="first:-mt-[5px] after:content-[''] after:block after:h-[5px]">
      <Header className="text-sm font-semibold text-gray-500 dark:text-zinc-300 px-4 py-1 truncate sticky -top-[5px] -mt-px -mx-1 z-10 bg-gray-100/60 dark:bg-zinc-700/60 backdrop-blur-md supports-[-moz-appearance:none]:bg-gray-100 border-y dark:border-y-zinc-700 [&+*]:mt-1">
        {props.title}
      </Header>
      <Collection items={props.items}>{props.children}</Collection>
    </Section>
  );
}
